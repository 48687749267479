import Header from "../components/Header"
import ContactForm from "../components/ContactForm"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider} from '@mui/material/styles';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },});

function ContactFormPage() {


  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <ContactForm />
      {/* <Section /> */}
      {/* <AboutUs /> */}
      {/* <Testimonial /> */}
      {/* <ContactUs /> */}
      {/* <Footer /> */}
      </ThemeProvider>
    </>

  );
}

export default ContactFormPage;
