import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';




function BrandExample() {
  return (
    <>
      <Navbar bg="primary" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={process.env.PUBLIC_URL + '/rect12975.png'}
              height="75"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <br />
    </>
  );
}

export default BrandExample;