import { Grid, Typography, Button, Box } from '@mui/material';
import useStyles from '../styles/styles';
import React, { useState } from "react";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/0e4ee220-d982-11ed-a62e-917cebf1b719"; // TODO - Update with thank you redirect page



const ContactForm = () => {
    const classes = useStyles();
    const [submitted, setSubmitted] = useState(false);
    const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon.</div>
      </>
    );
  }

  return (
    <Box className={classes.heroBox}>
        <Grid container spacing={1} justifyContent="center" className={classes.gridContainer}>
        <Grid item xs={16} md={7}>
            <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
            >
                <div className="mb-3 pt-0">
                    <input
                    type="text"
                    placeholder="Your name"
                    name="name"
                    className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                    required
                    />
                </div>
                    <div className="mb-3 pt-0">
                        <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                        required
                        />
                    </div>
                        <div className="mb-3 pt-0">
                            <textarea
                            placeholder="Your message"
                            name="message"
                            className="px-3 py-3 placeholder-gray-400 text-gray-600 relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                            required
                            />
                        </div>
                        <div className="mb-3 pt-0">
                            <button
                            type="submit"
                            class="btn btn-primary"
                            >
                            Send a message
                            </button>
                        </div>
            </form>
        </Grid>
        </Grid>
    </Box>


 
  );
};

export default ContactForm;