import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";

//routes 
import Home from "./routes/Home" 
import ErrorPage from "./error-page";
import ContactFormPage from "./routes/ContactFormPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";
import "./css/main.css"
//Routing based off of the main tutorial at React Router https://reactrouter.com/en/main/start/tutorial

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contactform",
    element: <ContactFormPage/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);