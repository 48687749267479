import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import myteam from '../images/myteam.jpg';
import useStyles from '../styles/styles';
import { useNavigate } from 'react-router-dom';

function Hero() {
  const classes = useStyles();
  const navigate = useNavigate();

  const contactFormPage = () => {
    navigate('/contactform')
  }

  return (
    <Box className={classes.heroBox}>
      <Grid container spacing={1} justifyContent="center" className={classes.gridContainer}>
        <Grid item xs={16} md={7}>
          <Typography variant="h3" fontWeight={700} className={classes.title}>
            Empowering Game Changing Innovation
          </Typography>
          <Typography variant="h6" className={classes.subtitle}>
            Interested in making finding the right innovation, or connecting with people who are looking for your innovations? 
            Reach out and learn how we can help increase your output today .
          </Typography>
          <Button
            variant="contained"
            color="primary"
            justifyContent="center"
            sx={{ width: '200px', fontSize: '16px' }}
            onClick={contactFormPage}
          >
            Contact Us
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;